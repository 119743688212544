*body {
  background-color: #f9faff;
  min-height: 100% !important;
  height: auto !important;
  // overflow-y: scroll !important;
  // padding-right: 0px !important;
}

.agent-payout-container {
  width: 100%;
  height: auto;
}

.agent-payout-header {
  background: #f1f2f2 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  max-width: 100%;
  padding: 10px;
  .header-title-text {
    font-size: 28px;
    font-weight: 600;
  }
}
.agent-payout-addNewProfile {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b37029;
  border-radius: 10px;
  opacity: 1;
  max-width: 100%;
  margin-top: 20px;
  padding: 20px 30px;
  .error-test {
    color: #ea1616;
    font-size: 10px;
    margin-bottom: 30px;
  }
}
.MuiButton-textPrimary-6855:hover {
  background-color: #b37029;
}
.pricing-schedule-Description {
  margin-top: 20px;
  text-align: center;
  width: 132px;
}

.pricing-schedule-description-box {
  margin-top: 20px;
  width: 87%;
  text-align: center;
}
