.select-min-width {
  min-width: 200px !important;
}

.select-outlined-style {
  background: #f9faff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  height: 44px;
}
/************/

.spn-required {
  color: #ea1616;
}

.custflex-jc-nowrap {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.custflex-dr-aic {
  flex-direction: row !important;
  display: flex !important;
  align-items: center !important;
  // flex-wrap: nowrap !important;
}
.custflex-dr {
  flex-direction: row !important;
  display: flex !important;
  // flex-wrap: nowrap !important;
}
.custflex-dc {
  flex-direction: column !important;
  display: flex !important;
  flex-wrap: nowrap !important;
}
.custflex-dc-aic {
  flex-direction: column !important;
  display: flex !important;
  align-items: center !important;
  flex-wrap: nowrap !important;
}

.fieldWithLabel {
  flex-direction: row !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  flex-wrap: nowrap !important;
}

.fieldWithLabel {
  flex-direction: row !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  flex-wrap: nowrap !important;
}

.errorAbs {
  position: absolute;
  display: block;
  color: #f44336;
  text-align: left;
  font-size: 11px;
}
.error {
  min-inline-size: max-content;
  display: block;
  color: #f44336;
  text-align: left;
  font-size: 11px;
}

.MuiTableSortLabel:active {
  color:"white"
}